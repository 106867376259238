import { graphql, useStaticQuery } from 'gatsby';
import Construction from 'images/construction.svg';
import Card from 'modules/card';
import React from 'react';

import css from './style.module.scss';

const Content: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      allPosts {
        nodes {
          blogPosts {
            featuredImage
            color
            alternative_id
            customAuthor
            publicationDate
            title
            author {
              fullName
            }
          }
        }
      }
    }
  `)

  const posts: Array<BlogPostType> = data.allPosts.nodes[0].blogPosts

  return (
    <div className={css.container}>
      <div className={css.leftcol}>
        <img src={Construction} alt="Construction" className={css.image} />
      </div>
      <div className={css.rightcol}>
        <div className={css.rightcolcontent}>
          {posts.map(
            ({
              title,
              publicationDate,
              author,
              customAuthor,
              color,
              alternative_id: id,
              featuredImage,
            }) => (
              <Card
                key={id}
                title={title}
                publishedAt={publicationDate}
                author={author}
                customAuthor={customAuthor}
                color={color}
                thumb={featuredImage}
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default Content
