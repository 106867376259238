import Application from 'modules/application';
import SEO from 'modules/seo';
import React from 'react';
import Home from 'routes/home';

const Page = () => (
  <Application>
    <SEO title="Blog" />
    <Home />
  </Application>
)

export default Page
