import Navigation from 'modules/navigation';
import React from 'react';

import Content from './content';

const Home: React.FC = () => (
  <>
    <Navigation fixed />
    <Content />
  </>
)

export default Home
