import Utils from 'core/utils';
import { Link } from 'gatsby';
import Plus from 'images/plus.svg';
import TitleCard from 'modules/title-card';
import React from 'react';

import Routes from '../../core/routes';
import css from './style.module.scss';

type CardProps = {
  title?: string
  publishedAt?: string
  author?: BlogPostAuthorType
  customAuthor?: string
  color?: string
  thumb?: string
}

const Card: React.FC<CardProps> = ({
  title,
  publishedAt,
  author,
  customAuthor,
  color,
  thumb,
}) => {
  const slug = Utils.generateSlugFromString(title)

  return (
    <Link to={`${Routes.home}${slug}`} className={css.container}>
      <div className={css.content}>
        <div className={css.leftcol}>
          <TitleCard
            title={title}
            publishedAt={publishedAt}
            author={author}
            customAuthor={customAuthor}
            color={color}
          />
        </div>
        <div className={css.rightcol}>
          <img src={thumb} alt="image" className={css.thumb} />
          <img src={Plus} alt="+" className={css.plus} />
        </div>
      </div>
    </Link>
  )
}

export default Card
